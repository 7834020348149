.success-svg {
    stroke-dasharray: 100;
    stroke-dashoffset: 100;
    animation: dash 2s ease-in-out infinite;
    width: 40px;
  }
  
  @keyframes dash {
    from {
      stroke-dashoffset: 100;
    }
    to {
      stroke-dashoffset: 0;
    }
  }
  