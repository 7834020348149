 
.slick-dots li button:before {
    font-size:15px;
}


.slick-dots li button:focus:before,
.slick-dots li button:hover:before,
.slick-dots li.slick-active button:before {
    color: teal;
}
.slick-prev:before {
    color:rgb(109, 109, 109);
  }
.slick-next:before {
    color:rgb(109, 109, 109);
}
.slick-initialized {
    overflow: hidden;
}