.slick-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    bottom: 10px;
    width: 100%;
  }
  
  .slick-dots li {
    margin: 0 5px;
    display: inline-block;
  }
  
  .slick-dots li button {
    font-size: 0;
    line-height: 0;
    display: block;
    width: 10px;
    height: 10px;
    padding: 5px;
    cursor: pointer;
    color: transparent;
    border: 0;
    outline: none;
    background: transparent;
  }
  
  .slick-dots li button:before {
    content: "";
    display: block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #6b76da;
    opacity: 0.5;
  }
  
  .slick-dots li.slick-active button:before {
    opacity: 1;
  }
  
.slick-prev::before {
    position: absolute;
    left: 10px;
    z-index: 1;
    color:#6b76da;
  }
  
.slick-next::before {
    position: absolute;
    right: 10px;
    z-index: 1;
    color:#6b76da;
  }
