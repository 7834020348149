body {
  margin: 0;
  font-family: "Urbanist", sans-serif;
}

code {
  font-family: "Urbanist", sans-serif;
}

button {
  font-family: "Righteous";
}
/*#logosvg{
  animation: fill 4s ease forwards;
}

#logosvg path:nth-child(1){
  stroke-dasharray: 26233.1875;
  stroke-dashoffset: 26233.1875;
  animation: line-anim 5s ease forwards;
}
@keyframes line-anim{
  to{
    stroke-dashoffset: 0;
  }
}
@keyframes fill{
  from{
    fill:transparent;
  }
  to{
    fill:white;
  }
}*/
#velikaSrbijasvg {
  animation: SRB 4s ease forwards infinite;
}
#velikaSrbijasvg path:nth-child(1) {
  stroke-dasharray: 41968.26953125;
  stroke-dashoffset: 41968.26953125;
  animation: line-anim 4s ease forwards infinite;
}
@keyframes SRB {
  from {
    fill: transparent;
  }
  to {
    fill: white;
  }
}
#logosvg-loading {
  animation: fill 2s ease forwards infinite;
}

#logosvg-loading path:nth-child(1) {
  stroke-dasharray: 26233.1875;
  stroke-dashoffset: 26233.1875;
  animation: line-anim 2s ease forwards infinite;
}
@keyframes line-anim {
  to {
    stroke-dashoffset: 0;
  }
}
@keyframes fill {
  from {
    fill: transparent;
  }
  to {
    fill: white;
  }
}

.logosvg-popup {
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.popup-container {
  position: fixed;
  width: 100%;
  height: 100vh;
  background: #00808052;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  z-index: 99999;
}
.popup-div-container {
  width: 90%;
  min-width: 90%;
  min-height: 40%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  border-radius: 10px;
}
.popup-title {
  margin: 10px 0px 14px 0;
  font-size: 30px;
  line-height: 1.1;
  text-align: center;
  font-weight: 400;
  font-family: "Righteous";
}
.popup-text {
  max-width: 500px;
  margin: 10px 0px 14px 0;
  font-size: 20px;
  line-height: 1.1;
  text-align: center;
  font-weight: 400;
  font-family: "Righteous";
  color: #ccc;
  padding: 0px 10px;
}
.form1 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 8px;
  margin: 20px 0px;
}
.label1 {
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 5px;
  text-transform: uppercase;
  font-family: "Righteous";
  color: rgb(39, 39, 39);
}
.input1 {
  outline: none;
  padding: 15px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  width: 80%;
  font-size: 13px;
  font-weight: 400;
  color: black;
  border: none;
  background-color: rgb(230, 230, 230);
  margin: 0px;
}
.input1::placeholder {
  font-size: 12px;
  font-weight: 400;
  color: rgb(97, 97, 97);
}
.input-required-text {
  padding: 10px 0px;
  margin: 0;
  color: #991f39;
  font-weight: 100;
  font-size: 12px;
  height: 14px;
}
.form-button1 {
  padding: 14px;
  border: 2px solid #5862bf;
  border-radius: 20px;
  background-color: #5862bf;
  cursor: pointer;
  font-weight: 500;
  color: white;
  margin-top: 20px;
  width: 80%;
}
.form-button1:hover {
  background-color: white;
  color: #5862bf;
}
.form-button1[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}

@media only screen and (min-width: 400px) {
  .popup-div-container {
    width: 40%;
  }
}
@media only screen and (max-width: 768px) {
  .popup-title {
    font-size: 30px;
  }
  .popup-text {
    font-size: 16px;
  }
  .popup-div-container {
    min-height: 70%;
  }
  .form-button1 {
    width: 90%;
    font-size: 13px;
    font-weight: 300;
  }
}

/* LOGIN  */
.login-title {
  font-size: 20px;
  text-align: center;
  font-weight: 400;
  font-family: "Righteous";
  padding: 0px 10px;
  text-transform: uppercase;
  margin-bottom: 30px;
}
.login-br {
  margin: 40px 0px 20px 0px;
  border-bottom: 1px solid #ccc;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-br-text {
  padding: 0;
  margin: 0 0 -6px 0;
  font-size: 12px;
  z-index: 2;
  background-color: #fff;
  display: inline;
  font-family: "Righteous";
  color: #1a1110;
}
.login-label {
  font-size: 14px;
  font-weight: 200;
  margin-bottom: 5px;
  margin-left: 5px;
  text-transform: uppercase;
  font-family: "Righteous";
  color: rgb(39, 39, 39);
  text-align: left;
  width: 90%;
}
.login-form-div {
  min-width: 90%;
}
.login-input-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  margin: 0px;
  margin-right: 30px;
}
@media only screen and (min-width: 400px) {
  .login-form-div {
    min-width: 40%;
    max-width: 40%;
  }
  .login-label {
    width: 80%;
  }
}
.login-form-button {
  padding: 8px;
  border: 2px solid #5862bf;
  border-radius: 20px;
  background-color: #5862bf;
  cursor: pointer;
  font-weight: 500;
  color: white;
  margin-top: 30px;
  width: 90%;
}
.login-form-button:hover {
  background-color: white;
  color: #5862bf;
}
.login-form-button[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
  .login-form-button {
    font-size: 11px;
    font-weight: 300;
  }
}
.login-form-button-reverse {
  padding: 8px;
  border: 2px solid #5862bf;
  border-radius: 20px;

  background-color: white;
  color: #5862bf;
  cursor: pointer;
  font-weight: 500;
  margin-top: 30px;
  width: 80%;
}
.login-form-button-reverse:hover {
  background-color: #5862bf;
  color: white;
}
.login-form-button-reverse[disabled] {
  opacity: 0.7;
  cursor: not-allowed;
}

@media only screen and (max-width: 768px) {
  .login-form-button-reverse {
    font-size: 11px;
    font-weight: 300;
  }
}
.register-top-text {
  font-family: "Urbanist";
  font-weight: 100;
  font-size: 14px;
  padding: 0px 30px 0px 10px;
}
.register-label-div {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 90%;
}
@media only screen and (min-width: 400px) {
  .register-label-div {
    width: 80%;
  }
}
.register-optional-text {
  font-family: "Urbanist";
  font-weight: 300;
  margin: 0;
  font-size: 12px;
  margin-right: 5px;
  padding: 0;
}
.register-note-label {
  font-size: 11px;
  font-weight: 200;
  margin-bottom: 5px;
  margin-left: 5px;
  text-transform: uppercase;
  font-family: "Righteous";
  color: rgb(39, 39, 39);
  text-align: left;
  width: 90%;
}
.login-error-note-div {
  margin: 10px;
  padding: 20px;
  background-color: #d12c377c;
  color: #991f39;
  text-align: center;
  font-size: 12px;
  display: none;
}
.register-avatar-div {
  background-color: rgb(230, 230, 230);
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  margin: 10px;
  border-radius: 20px;
  padding: 20px;
}
.register-avatar-img {
  height: 100px;
  width: 100px;
  border-radius: 100px;
  margin: 20px 0px;
}
.register-avatar-title {
  font-family: "Righteous";
  font-weight: 100;
  font-size: 14px;
  padding: 0;
  margin: 0;
  margin-bottom: 40px;
}
.register-avatar-ul {
  font-size: 14px;
}

.checkoutformjsx-input1 {
  outline: none;
  padding: 15px 20px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.3);
  width: 80%;
  font-size: 13px;
  font-weight: 400;
  color: black;
  border: none;
  background-color: rgb(230, 230, 230);
  margin: 0px;
}
@media only screen and (min-width: 400px) {
  .checkoutformjsx-input1 {
    width: 80%;
  }
  .register-note-label {
    padding-left: 20%;
  }
}
.checkoutformjsx-input1::placeholder {
  font-size: 12px;
  font-weight: 400;
  color: rgb(97, 97, 97);
}

/* CSS */
#signInDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px; /* Adjust as needed */
  overflow: hidden; /* This is to ensure the border radius applies to the button */
}
