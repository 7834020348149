.slick-prev:before {
    color:teal;
  }
.slick-next:before {
    color:teal;
}
.slick-slider {
  overflow-x: hidden !important;
  overflow: hidden !important;
}

.slick-prev::before {
  position: absolute;
  left: 5px;
  font-size: 20px;
  z-index: 1;
}

.slick-next::before {
  position: absolute;
  right: 5px;
  z-index: 1;
}
.slick-initialized{
  overflow: hidden;
}