
.tl-wrapper{
    float:left;
    position:relative;
    width:100%;
}
.px-30{
    padding-left:30px;
    padding-right:30px
}
@media (min-width:764px){
    .px-30{
        padding-left:60px;
        padding-right:60px
    }
}

.tl-edges{
    max-width:100%;
    background-color: #ccc;
    overflow-x:hidden;
}
.slider{
    height:100vh;
    padding:0 5vw;
}
@media (min-width:764px){
    .slider{
        padding:0 25vw
    }
}
.slider--overview{
    height:auto;
    margin:0;
    max-width:1366px;
    padding:20px 0px;
    

}


.slider--overview .slider__list{
display:grid;
    height:auto;
    overflow:unset;
    top:auto;
    width:auto;
    grid-template-columns:repeat(1,minmax(0,1fr));
    gap: 10px 100px;
}

  

@media (min-width:768px){
    .slider--overview .slider__list{
    grid-template-columns:repeat(3,minmax(0,1fr));
    gap:0px 110px;
        
    }
}
.slider--overview .slider__item{
    padding:0;
    position:relative;
    width:100%;
}
.slider--overview .slider__figure{
    aspect-ratio:16/9;
    -webkit-transform:scale(1);
    transform:scale(1);
    transition:none;

}

.slider--overview .slider__title-group{
    font-weight:600;
    margin-top:20px;
    opacity:1;
    position:static;
    text-align:left;
    transition:none;
    visibility:visible
}
.slider--overview .slider__title{
    font-size:20px;
    letter-spacing:.125rem;
    line-height:2.1;
    text-transform: uppercase;
    /* text-decoration:underline;
    text-underline-offset:8px */
}
.slider__list{
    display:flex;
    height:60vh;

    transition: all 0.5s ease-in-out; 
}

.slider__item{
    display:flex;
    flex:none;
    font-size:30px;
    font-weight:700;
    height:100%;
    width:100vw;
    align-items:flex-start;
    justify-content:center;
    padding:0 6px;
    /* width:75vw; */
    list-style: none;
    background-color: orange;
    
}

@media only screen and (min-width:768px){
    .slider__item{
        margin-bottom: 40px;
    }
}
@media(min-height:764px){
    .slider__item{
        align-items:center
    }
}

.slider__figure{
    aspect-ratio:284/320;
}
.spasko {
    /* visibility: hidden; */
    /* display: none; */

}

.slider__item--infocus .slider__figure{
    padding-top:0;
    -webkit-transform:scale(1);
    transform:scale(1);

    
}
.slider__item--infocus .slider__title-group{
    opacity:1;
    transition:opacity .3s ease-in-out .75s,-webkit-transform .75s ease-in-out;
    transition:opacity .3s ease-in-out .75s,transform .75s ease-in-out;
    transition:opacity .3s ease-in-out .75s,transform .75s ease-in-out,-webkit-transform .75s ease-in-out;
    visibility:visible;
}

.slider__image{
    object-fit:cover;
    width:100%;
    /*transition:object-position .75s ease-in-out;
    transition:object-position .75s ease-in-out,-o-object-position .75s ease-in-out;*/
}
.slider__title{
    font-size:25px;
    letter-spacing:.15rem;
    line-height:1.25;
    color:#fff;
}
.slider__text{
    font-size: 12px;
    text-align: left;
    color:#fff;
    font-weight: 100;
}
.slider__item--right-panning .slider__figure{
    
}

.slider__list-djm{
    
}

.h1__djm{
    white-space: nowrap;
    font-size: 30px;
    text-align: left;
    color:#fff;
}

.h-9{
    height:9px
    
}
.mt-2{
    margin-top:2px
}
.mr-16{
    margin-right:16px;
    font-family: "Righteous";
    color:white;
    font-size: 15px;
    text-transform: uppercase;
}
.btn--overview{
    display: flex;
    align-items: center;
    justify-content: center;
    border:none;
    border-bottom: 1px solid white;
    position:sticky;
    background-color: transparent;
    top:100px;
    margin-left:10px;
    z-index:9;
}